import { liquidCustomerStore } from "../../../store/store";
import { FormView } from "../../UI/view/form-view";
import { ListView, ListViewColumnType } from '../../UI/view/list-view';
import { FormItemType } from "../../UI/view/component-map";
import { createView } from "../../UI/view/view";

import { customerPermissions } from "./customer-data-source";
import { getCOCReports, getDeliveryTypes } from "../../../services/requests";
import { addDays, addYears, currentRange, futureRange, pastRange } from "../../../services/form-helpers";
import { liquidCustomerPricingEditColumns } from "./customer-liquid-pricing";
import { CustomerLiquidHistoryListViewComponent } from "./customer-liquid-history-list";

export const customerLiquidEdit = {
  editMode: true,
  pageHeader: 'Liquid Customer',
  breadcrumbs: [
    {
      name: 'Customers',
      icon: 'people',
      to: '/customers',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            {
              type: FormItemType.infoPane,
              infoListItems: [
                {
                  label: 'Customer ID',
                  name: 'customerId',
                },
                {
                  label: 'Address',
                  name: 'shippingAddress',
                },
                {
                  label: 'City',
                  name: 'city',
                },
                {
                  label: 'State',
                  name: 'state',
                },

              ],
              header: (state: any) => state.liquidcustomers?.current?.data?.customerName,
            },
            {
              type: FormItemType.fileAttachments,
              entityTypeId: 10,
              entityName: 'Liquid Customers',
              permissions: customerPermissions,
            },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'General Info',
              items: [
                {
                  type: FormItemType.row,
                  items: [
                    {
                      items: [
                        {
                          type: FormItemType.fieldset,
                          label: 'Details',
                          items: [
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Customer ID', name: 'customerId', type: FormItemType.text, number: true, required: true, disabled: () => true, },
                                { label: 'Customer Name', name: 'customerName', type: FormItemType.text, number: false, required: true, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Shipping Address', name: 'shippingAddress', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Shipping Address 2', name: 'shippingAddress2', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'City', name: 'city', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'State', name: 'state', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Zip', name: 'zip', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'PO Number', name: 'poNumber', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'BDTCPO', name: 'bdtcpo', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                {
                                  label: 'Specs',
                                  name: 'specs',
                                  type: FormItemType.select,
                                  options: [{ text: "", id: null }, { text: "Liquid", id: "Liquid" }, { text: "High Purity", id: "High Purity" }, { text: "Special", id: "Special" }],
                                  number: false,
                                  required: false,
                                  defaultValue: undefined,
                                },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Spec Special', name: 'specSpecial', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'AFS Required', name: 'afsrequired', type: FormItemType.yn, number: false, required: true, defaultValue: 'N', },
                              ]
                            },
                            { label: 'Delivery Request', name: 'deliveryRequest', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                            { label: 'Other Requests', name: 'otherRequests', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Maximum Shipping Pressure', name: 'maximumShippingPressure', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
                                { label: 'Maximum Shipping Weight', name: 'maximumShippingWeight', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
                              ]
                            },
                            {
                              label: 'Status',
                              name: 'status',
                              type: FormItemType.select,
                              required: true,
                              options: [{ text: "", id: null }, { text: "Active", id: "Active" }, { text: "In-Active", id: "In-Active" }]
                            },
                            {
                              name: 'cocid',
                              label: 'COC',
                              type: FormItemType.select,
                              defaultValue: 0,
                              required: true,
                              options: async () => {
                                const res = await getCOCReports();
                                return [{ id: '', text: '-- Select --' }, ...res.map(rec => ({ id: rec.cocid, text: rec.cocName }))];
                              }
                            },
                            {
                              label: 'COC Text',
                              name: 'cocdocumentText',
                              type: FormItemType.textarea,
                              number: false,
                              required: false,
                              defaultValue: undefined,
                              tooltip: `NOTE: If needed, please use the following input place holders (%abc%) in the COC text:
                              Date = %DATE%, Container = %CONTAINER%, Order Number = %ORDERNUMBER%,
                              Temperature = %TEMPERATURE%, Pressure = %PRESSURE%, Purity = %PURITY%,
                              Level = %LEVEL%`
                            },

                            { label: 'Driver Instruction', name: 'driverInstruction', type: FormItemType.textarea, number: false, required: false, defaultValue: undefined, },
                            { label: 'Driver Routing', name: 'driverRouting', type: FormItemType.textarea, number: false, required: false, defaultValue: undefined, },
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  label: 'Region',
                                  name: 'region',
                                  type: FormItemType.select,
                                  number: false,
                                  required: false,
                                  defaultValue: undefined,
                                  options: [
                                    { id: null, text: '-- Select --' },
                                    { id: 'Internal', text: 'Internal' },
                                    { id: 'International Directs', text: 'International Directs' },
                                    { id: 'PDI', text: 'PDI' },
                                    { id: 'Praxair Asia', text: 'Praxair Asia' },
                                    { id: 'Praxair Electronics', text: 'Praxair Electronics' },
                                    { id: 'Praxair Europe', text: 'Praxair Europe' },
                                    { id: 'Praxair Mexico', text: 'Praxair Mexico' },
                                    { id: 'Praxair Puerto Rico', text: 'Praxair Puerto Rico' },
                                    { id: 'Praxair South America', text: 'Praxair South America' },
                                    { id: 'Repair Shop', text: 'Repair Shop' },
                                    { id: 'US Directs', text: 'US Directs' },
                                    { id: 'US Gov', text: 'US Gov' },
                                  ]
                                },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Ave Net Vol', name: 'aveNetVol', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
                                { label: 'Miles', name: 'miles', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Turn Days', name: 'turnDays', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
                                { label: 'Container Style', name: 'requiredContainerStyle', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Container MAWP (PSIG)', name: 'requiredContainerMawp', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
                                { label: 'Helium Shield Required', name: 'heliumShieldRequired', type: FormItemType.yesno, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'PBC Required', name: 'pbcrequired', type: FormItemType.yesno, number: false, required: false, defaultValue: undefined, },
                                {
                                  label: 'Recommended Fill Plant',
                                  name: 'recommendedFillPlant',
                                  type: FormItemType.select,
                                  options: [
                                    { id: null, text: '-- Select --' },
                                    { id: 'Algeria', text: 'Algeria' },
                                    { id: 'Amur', text: 'Amur' },
                                    { id: 'Bushton', text: 'Bushton' },
                                    { id: 'Darwin', text: 'Darwin' },
                                    { id: 'Exxon', text: 'Exxon' },
                                    { id: 'Qatar', text: 'Qatar' },
                                    { id: 'Tumbleweed', text: 'Tumbleweed' },
                                    { id: 'Ulysses', text: 'Ulysses' },
                                  ],
                                  number: false,
                                  required: false,
                                  defaultValue: undefined,
                                },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'One Way Travel Time', name: 'oneWayTravelTime', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
                                { label: 'Hourly Maintenance Rate', name: 'hourlyMaintenanceRate', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Containers Maintained By Praxair', name: 'containersMaintainedByPraxair', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Container Return Email', name: 'containerReturnEmail', type: FormItemType.yesno, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Email Address', name: 'emailAddress', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Container Ship Email', name: 'containerShipEmail', type: FormItemType.yesno, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Bushton Miles', name: 'bushtonmiles', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
                                { label: 'Duke Miles', name: 'dukemiles', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Ulysses miles', name: 'ulyssesmiles', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
                                { label: 'Exxon Miles', name: 'exxonmiles', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Is Customs Invoice Required', name: 'isCustomsInvoiceRequired', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Freight Forwarder Email', name: 'freightForwarderEmail', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Exclude from Locations', name: 'isNonLocationCustomer', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Shield Activation Required', name: 'shieldActivationRequired', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  label: 'Default Delivery Type',
                                  name: 'defaultDeliveryTypeId',
                                  type: FormItemType.select,
                                  options: async () => {
                                    const dt = await getDeliveryTypes(1);
                                    return [{ id: '', text: '-- Select --' }, ...dt.map(rec => ({ id: rec.id, text: rec.name }))];
                                  },
                                  number: true,
                                  required: false,
                                  defaultValue: undefined,
                                },
                                { label: 'Bushton Transit Time', name: 'bushtonTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Ulysses Transit Time', name: 'ulyssesTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Duke Transit Time', name: 'dukeTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Exxon Transit Time', name: 'exxonTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'APCI Transit Days', name: 'apcitransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Qatar Transit Time', name: 'qatarTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Algeria Transit Time', name: 'algeriaTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Is Treat As Domestic For Planning', name: 'isTreatAsDomesticForPlanning', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Country', name: 'country', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'USA Cutoff to Arrival Days', name: 'usacutoffToArrivalDays', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
                                { label: 'QTR Cutoff to Arrival Days', name: 'qtrcutoffToArrivalDays', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
                              ]
                            },
                            { label: 'Algeria Cutoff to Arrival Days', name: 'algcutoffToArrivalDays', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  label: 'Legacy Organization',
                                  name: 'legacyOrganization',
                                  type: FormItemType.select,
                                  options: [
                                    { id: 'Praxair', text: 'Praxair' },
                                    { id: 'Linde APAC', text: 'Linde APAC' },
                                    { id: 'Linde EMEA', text: 'Linde EMEA' },
                                  ],
                                  number: false,
                                  required: true,
                                  defaultValue: 'Praxair',
                                },
                              ]
                            },
                            { label: 'Logistics Only Notes', name: 'logistIcsOnlyNotes', type: FormItemType.textarea, number: false, required: false, defaultValue: undefined, },
                          ]
                        },
                      ]
                    },
                    {
                      items: [
                        {
                          type: FormItemType.fieldset,
                          label: 'Consignee Details',
                          items: [
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Consignee Name', name: 'consigneeName', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Consignee Number', name: 'consigneeNumber', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Consignee Email', name: 'consigneeEmail', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Consignee Phone', name: 'consigneePhone', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Consignee Fax', name: 'consigneeFax', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Consignee Address', name: 'consigneeAddress', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Consignee Address 2', name: 'consigneeAddress2', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Consignee City', name: 'consigneeCity', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Consignee State', name: 'consigneeState', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Consignee Zip', name: 'consigneeZip', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Consignee Address 3', name: 'consigneeAddress3', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Consignee Address 4', name: 'consigneeAddress4', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Consignee Address 5', name: 'consigneeAddress5', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                          ]
                        },
                        {
                          type: FormItemType.fieldset,
                          label: 'Billing',
                          items: [
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Bypass Liquid Billing', name: 'bypassLiquidBilling', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Bypass Liquid Billing Reason', name: 'bypassLiquidBillingReason', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Linked Credit In Inland Freight', name: 'linkedCreditInTransport', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Is Analytical Charge In Inland Freight', name: 'isAnalyticalChargeInTransport', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [

                                { label: 'Billed Net (No Credit)', name: 'billNet', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'DAT', name: 'isDap', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Bypass Customer', name: 'bypassCustomer', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Link Credit Debit', name: 'linkCreditDebit', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },

                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Is Ocean Freight Separate Invoice Page', name: 'isOceanFreightSeparateInvoicePage', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Is Metric Units', name: 'isMetricUnits', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Is Nitrogen Charge In Inland Freight', name: 'isNitrogenChargeInTransport', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Billed Through NALS', name: 'isNalscustomer', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Is Linked Credit Mandatory', name: 'isLinkedCreditMandatory', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Packing List Comments', name: 'packingListComments', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Inv. Ship To 1', name: 'shipTo1', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Inv. Ship To 2', name: 'shipTo2', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Inv. Ship To 3', name: 'shipTo3', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Inv. Ship To 4', name: 'shipTo4', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },

                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Inv. Ship To 5', name: 'shipTo5', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Is Transport Charge Floor Zero', name: 'isTransportChargeFloorZero', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Invoice Customer Code', name: 'invoiceCustomerCode', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Invoice TC', name: 'invoiceTc', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Invoice CO', name: 'invoiceCo', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Invoice Country', name: 'invoiceCountry', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Invoice Loc', name: 'invoiceLoc', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Invoice Terms Code', name: 'invoiceTermsCode', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Invoice Terms of Payment', name: 'invoiceTermsofPayment', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Invoice Terms of Sale', name: 'invoiceTermsofSale', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Invoice Remittance Info', name: 'invoiceRemittanceInfo', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Billing Address 1', name: 'billingAddress1', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Billing Address 2', name: 'billingAddress2', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Billing Address 3', name: 'billingAddress3', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Billing Address 4', name: 'billingAddress4', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Billing Address 5', name: 'billingAddress5', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },

                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Billing PO Number Required', name: 'billingPoNumberRequired', type: FormItemType.checkboxField, number: false, required: false, defaultValue: undefined, },
                                { label: 'Billing RO Number Required', name: 'billingRoNumberRequired', type: FormItemType.checkboxField, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Customer Billing Email', name: 'customerBillingEmail', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Billing Comments', name: 'billingComments', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Is Liq Billing Email Enabled', name: 'isLiqBillingEmailEnabled', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Billing Addendum', name: 'billingAddendum', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Include Fill Sheet With Invoice', name: 'includeFillSheetWithInvoice', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Is Split Inland Freight Charge', name: 'isSplitTransportCharge', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'No Invoice COA', name: 'isNoInvoiceCoa', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Split Ocean Charge', name: 'splitOceanCharge', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                              ]
                            },
                            { label: 'Override Residual Pricing', name: 'overrideResidualPricing', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                            {
                              type: FormItemType.textarea,
                              name: 'pricingNotes',
                              label: 'Pricing Notes'
                            },
                          ]
                        },
                        {
                          type: FormItemType.fieldset,
                          label: 'Rental',
                          items: [
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Rental Email', name: 'rentalEmail', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Rental Billing PX Number', name: 'rentalBillingPxNumber', maxLength: 20, type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
                                { label: 'Is Rent On Ship Customer', name: 'isRentOnShipCustomer', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                { label: 'Is Plant To Plant Rent', name: 'isPlantToPlantRent', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                                { label: 'Is Bypass Rental Billing', name: 'isBypassRentalBilling', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
                              ]
                            },
                          ]
                        },
                      ]
                    }
                  ]
                },

              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: customerPermissions,
                }
              ]
            },
            {
              label: 'Purity Requirements',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Customer Purity Requirements',
                  items: [
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'Hide Analytical Results (COC 3)',
                          type: FormItemType.checkboxField,
                          name: 'liquidCustomerPurityRequirements.hideAnalyticalResults',
                          defaultValue: false
                        },

                      ]
                    },
                    {
                      label: 'Requires Reading',
                      type: FormItemType.checkboxField,
                      name: 'liquidCustomerPurityRequirements.requiresReading',
                      defaultValue: true
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'Neon Max',
                          name: 'Neon Max',
                          width: 140
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.neonMax',
                          number: true,
                          defaultValue: 2,
                          fitToContent: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeNeon'),
                          width: 100,
                          min: 0,

                        },
                        {
                          name: 'liquidCustomerPurityRequirements.neonEngUnits',
                          type: FormItemType.select,
                          defaultValue: 'PPM',
                          tooltip: 'Units',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' }
                          ],
                          fitToContent: true
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeNeon',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include'
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'O2 Max',
                          name: 'O2 Max',
                          width: 140
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.oxygenMax',
                          number: true,
                          defaultValue: 1,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeOxygen'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.oxygenEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          required: true,
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' }
                          ],
                          fitToContent: true
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeOxygen',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'N2 Max',
                          name: 'N2 Max',
                          width: 140
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.nitrogenMax',
                          number: true,
                          defaultValue: 5,
                          width: 100,
                          fitToContent: true,
                          min: 0,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeNitrogen'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.nitrogenEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeNitrogen',
                          defaultValue: false,
                          tooltip: 'Include',
                          type: FormItemType.checkboxField
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'CO2 Max',
                          name: 'CO2 Max',
                          width: 140
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.carbonDioxideMax',
                          number: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeCarbonDioxide'),
                          width: 100,
                          defaultValue:0.5,
                          min: 0,
                          fitToContent: true
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.carbonDioxideEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          required: true,
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeCarbonDioxide',
                          defaultValue: false,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'CO Max',
                          name: 'CO Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.carbonMonoxideMax',
                          number: true,
                          defaultValue: 0.5,
                          width: 100,
                          fitToContent: true,
                          min: 0,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeCarbonMonoxide'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.carbonMonoxideEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeCarbonMonoxide',
                          defaultValue: false,
                          tooltip: 'Include',
                          type: FormItemType.checkboxField,
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'H20 Max',
                          name: 'H20 Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.moistureMax',
                          number: true,
                          defaultValue: 1.5,
                          width: 100,
                          fitToContent: true,
                          min: 0,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeMoisture'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.moistureEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeMoisture',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'H2 Max',
                          name: 'H2 Max',
                          width: 140,
                          required: true
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.hydrogenMax',
                          number: true,
                          defaultValue: 1,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeHydrogen'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.hydrogenEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeHydrogen',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'THC Max',
                          name: 'thc max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.thcmax',
                          number: true,
                          defaultValue: 0.5,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeThc'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.thcengUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeThc',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'CH4 Max',
                          name: 'CH4 Max',
                          width: 140,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeMethane'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.methaneMax',
                          number: true,
                          width: 100,
                          defaultValue: 0.5,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeMethane'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.methaneEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeMethane',
                          defaultValue: false,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'Assay',
                          name: 'AssayLabel',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.assay',
                          number: true,
                          defaultValue: 0,
                          min: 0,
                          width: 100,
                          fitToContent: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeAssay'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.assayEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: '%',
                          options: [
                            { id: '%', text: '%---' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeAssay',
                          defaultValue: false,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'CO + CO2 Max',
                          name: 'CO + CO2 Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.carbonMonoxideCarbonDioxideMax',
                          number: true,
                          defaultValue: 0.5,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeCarbonMonoxideCarbonDioxide'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.carbonMonoxideCarbonDioxideEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeCarbonMonoxideCarbonDioxide',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'O2 + Argon Max',
                          name: 'O2 + Argon Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.oxygenArgonMax',
                          number: true,
                          defaultValue: null,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeOxygenArgon'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.oxygenArgonEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeOxygenArgon',
                          defaultValue: false,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'N2 + Argon Max',
                          name: 'N2 + Argon Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.nitrogenArgonMax',
                          number: true,
                          defaultValue: 5,
                          min: 0,
                          width: 100,
                          fitToContent: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeNitrogenArgon'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.nitrogenArgonEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeNitrogenArgon',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'Impurities, PPM by Vol, Max',
                          name: 'Impurities, PPM by Vol, Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.impuritiesPpmvolMax',
                          number: true,
                          defaultValue: null,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('liquidCustomerPurityRequirements.includeImpuritiesPpmvol'),
                        },
                        {
                          name: 'liquidCustomerPurityRequirements.includeImpuritiesPpmvol',
                          defaultValue: false,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    //Impurities, PPM by Vol, Max

                  ]
                }
              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: customerPermissions,
                }
              ]
            },
            {
              label: 'Pricing',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Pricing',
                  items: [
                    {
                      type: FormItemType.editTable,
                      toolbar: [
                        {
                          type: FormItemType.button,
                          label: 'Copy Current Schedule',
                          handler: ({ methods, editTableProps }) => {
                            const { setData, partitions, setActiveKey } = editTableProps;
                            const data = methods.getValues()['liquidCustomerPricing'] || [];
                            const current = partitions?.length > 1 ? partitions[1] : null
                            let newRecords = [];
                            if (current?.length) {
                              for (let record of current) {
                                newRecords.push({
                                  ...record,
                                  id: null,
                                  effectiveDate: addDays(record.expirationDate, 1),
                                  expirationDate: addYears(record.expirationDate, 1),
                                  metaInformation: { ...record.metaInformation, liquidCustomerPricingId: undefined }
                                })
                              }
                              const d = [...data, ...newRecords];
                              setData(d);
                              methods.setValue('liquidCustomerPricing', d);
                              setActiveKey('2');
                            }
                          }
                        },
                      ],
                      partitionView: [
                        {
                          filter: (record) => pastRange(record.effectiveDate, record.expirationDate),
                          label: 'Expired Pricing',
                          emptyText: 'There are no expired pricing records',
                          id: 'expired'
                        },
                        {
                          filter: (record) => currentRange(record.effectiveDate, record.expirationDate),
                          label: 'Current Pricing',
                          emptyText: 'There are no current pricing records',
                          id: 'current'
                        },
                        {
                          filter: (record) => futureRange(record.effectiveDate, record.expirationDate),
                          label: 'Future Pricing',
                          emptyText: 'There are no future pricing records',
                          id: 'future'
                        }
                      ],
                      name: 'liquidCustomerPricing',
                      columns: liquidCustomerPricingEditColumns
                    }
                  ]
                }
              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: customerPermissions,
                }
              ]
            },
            {
              label: 'Audit Logs',
              unMountOnExit: true,
              items: [
                {
                  type: FormItemType.auditLogs,
                  name: 'LiquidCustomers',
                }
              ]
            },
            {
              label: 'History',
              items: [
                {
                  type: FormItemType.customComponent,
                  component: CustomerLiquidHistoryListViewComponent,
                }
              ]
            },
          ]
        }
      ]
    }
  ]

} as FormView;

export const CustomerLiquidEdit = createView({ form: customerLiquidEdit, repository: liquidCustomerStore });